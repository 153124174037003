import axios from 'axios';
// Função para realizar o hashing de dados (SHA-256)
async function hashData(data) {
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // Cria um array de bytes
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return hashHex; // Retorna o hash em formato hexadecimal
}
export async function sendPixel(eventName, userData = {}, customData = {}) {
  const hashedData = {};
  if (userData.email) {
    // email
    hashedData.em = await hashData(userData.email);
  }
  if (userData.nome) {
    // nome
    hashedData.fn = await hashData(userData.nome);
  }
  if (userData.sobrenome) {
    // sobrenome
    hashedData.ln = await hashData(userData.sobrenome);
  }
  if (userData.phone) {
    // telefones
    hashedData.ph = await hashData(userData.phone);
  }
  if (userData.genero) {
    // gênero
    hashedData.ge = await hashData(userData.genero);
  }
  if (userData.data_nascimento) {
    // data de nascimento
    hashedData.db = await hashData(userData.data_nascimento);
  }
  if (userData.cidade) {
    // cidade
    hashedData.ct = await hashData(userData.cidade);
  }
  if (userData.estado) {
    // estado
    hashedData.st = await hashData(userData.estado);
  }
  if (userData.cep) {
    // cep
    hashedData.zp = await hashData(userData.cep);
  }
  if (userData.pais) {
    // país
    hashedData.country = await hashData(userData.pais);
  }

  // Envia os dados ao Pixel
  axios.post(
    "/api/v1/facebook/pixel/event",
    {
      event_name: eventName,
      event_params: hashedData,
      custom_data: customData,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  ).then(function (response) {
    console.log("Pixel enviado com sucesso:", response.data);
  }).catch(function (error) {
    console.error("Erro ao enviar pixel:", error.response.data);
  });
}

