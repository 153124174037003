import moment from "moment";
import "moment/locale/pt-br"; // Importa o idioma português para o Moment.js
import axios from 'axios';

export function formatMoeda(valor) {
    return parseFloat(valor).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
    });
}

// Função para formatar datas para o padrão brasileiro
export function formatData(data) {
    return moment(data).format("DD/MM/YYYY");
}

// Função para formatar datas para o padrão brasileiro
export function formatDataHora(data) {
    return moment(data).format("DD/MM/YYYY HH:mm");
}

export function getIpAddress() {
    axios.get('https://api.ipify.org?format=json').then(function (response) {
        
        return response.data;
    });
    
}