import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "../stores/auth";
import { sendPixel } from "@/services/facebookPixelService";

export const authGuard = async (to, from, next) => {
  const authStore = useAuthStore();
  const isAuthenticated = await authStore.verifyStatus();
  if (!isAuthenticated) {
    next({ name: "LoginAdmin" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home/index.vue"),
    redirect: "/acoes",
    children: [
      {
        path: "/acoes",
        name: "Principal",
        component: () => import("../views/principal/index.vue"),
      },
      {
        path: "/meus_numeros/:slug/:id",
        name: "MeusNumerosId",
        component: () => import("../views/meus_numeros/index.vue"),
      },
      {
        path: "/campanha/:id",
        name: "Campanha",
        component: () => import("../views/campanha/index.vue"),
      },
      {
        path: "/revisao",
        name: "Revisao",
        component: () => import("../views/revisao/index.vue"),
      },
      {
        path: "/transacao/:id",
        name: "Transacao",
        component: () => import("../views/transacao/index.vue"),
      },
      {
        path: "/politica_privacidade",
        name: "PoliticaPrivacidade",
        component: () => import("../views/politica_privacidade/index.vue"),
      },
      {
        path: "/pagina/:id",
        name: "CustomPage",
        component: () => import("../views/custompage/CustomPagePrincipal.vue"),
      },
    ],
  },
  {
    path: "/admin/sair",
    name: "Logout",
    beforeEnter: async (to, from, next) => {
      localStorage.removeItem("token");
      localStorage.removeItem("usuario");
      const authStore = useAuthStore();
      authStore.logout();
      next("/admin");
    },
  },
  {
    path: "/admin",
    name: "Administracao",
    component: () => import("../admin/views/index.vue"),
    redirect: "/admin/login",
    children: [
      {
        path: "/admin/login",
        name: "LoginAdmin",
        component: () => import("../admin/views/login/index.vue"),
      },
      {
        path: "/admin/aut-dois-fatores",
        name: "autDoisFatores",
        component: () => import("../admin/views/login/autDoisFatores.vue"),
      },
    ],
  },
  {
    path: "/admin/home",
    name: "AdminHome",
    component: () => import("../admin/views/home/index.vue"),
    redirect: "/admin/dashboard",
    beforeEnter: authGuard,
    children: [
      {
        path: "/admin/dashboard",
        name: "AdminDashboard",
        component: () =>
          import("../admin/views/dashboard/dashboardPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/configuracao",
        name: "AdminConfiguracao",
        component: () =>
          import("../admin/views/configuracoes/configuracoesPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/clientes",
        name: "AdminClientes",
        component: () =>
          import("../admin/views/clientes/clientesPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/cotas",
        name: "AdminCotas",
        component: () => import("../admin/views/cotas/cotasPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/ganhadores",
        name: "AdminGanhadores",
        component: () =>
          import("../admin/views/ganhadores/ganhadoresPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/upsell",
        name: "AdminUpsell",
        component: () => import("../admin/views/upsell/upsellPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/order_bump",
        name: "AdminOrderBump",
        component: () =>
          import("../admin/views/orderbump/orderbumpPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/rodape",
        name: "AdminRodape",
        component: () => import("../admin/views/rodape/rodapePrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/gateway",
        name: "AdminGateway",
        component: () => import("../admin/views/gateway/gatewayPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/usuarios",
        name: "AdminUsuarios",
        component: () =>
          import("../admin/views/usuarios/usuariosPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/relatorios",
        name: "AdminRelatorios",
        component: () =>
          import("../admin/views/relatorios/relatorioPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/afiliados",
        name: "AdminAfiliados",
        component: () =>
          import("../admin/views/afiliados/afiliadosPrincipal.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/campanhas",
        name: "AdminRifas",
        component: () => import("../admin/views/campanhas/index.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/criar/campanha",
        name: "AdminNovaCampanhaEtapa1",
        component: () =>
          import("../admin/views/campanhas/novacampanha_etapa_1.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/editar/campanha/:id",
        name: "AdminNovaCampanhaEtapa2",
        component: () =>
          import("../admin/views/campanhas/novacampanha_etapa_2.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/visualizar/campanha/:id",
        name: "AdminVisualizarCampanhaEtapa2",
        component: () => import("../admin/views/campanhas/visualizar.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/ranking",
        name: "AdminRanking",
        component: () => import("../admin/views/ranking/index.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/ranking/:id",
        name: "AdminRankingId",
        component: () => import("../admin/views/ranking/RankingView.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/pedidos",
        name: "AdminPedidos",
        component: () => import("../admin/views/pedidos/index.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/historico",
        name: "AdminHistorico",
        component: () => import("../admin/views/historico/index.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/tutoriais",
        name: "AdminTutoriais",
        component: () => import("../admin/views/pagepadrao/index.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/suporte",
        name: "AdminSuporte",
        component: () => import("../admin/views/pagepadrao/index.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/sugestoes",
        name: "AdminSugestoes",
        component: () => import("../admin/views/pagepadrao/index.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/admin/sair",
        name: "AdminSair",
        component: () => import("../admin/views/pagepadrao/index.vue"),
        beforeEnter: authGuard,
      },{
        path: "/admin/sitebuilder",
        name: "AdminSiteBuilder",
        component: () => import("../admin/views/grapejs/SiteBuilder.vue"),
        beforeEnter: authGuard,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  if (!to.path.startsWith("/admin")) {
    sendPixel(
      "PageView",
      {
        phone: localStorage.getItem("identificaoCliente") ?? null,
      },
      {
        url: to.fullPath,
      }
    );
    console.log(`PageView rastreado: ${to.fullPath}`, ["PageView",
      {
        phone: localStorage.getItem("identificaoCliente") ?? null,
      },
      {
        url: to.fullPath,
      }]);
  } else {
    if (to.path.startsWith("/admin")) {
      console.log(
        "Rastreamento não realizado para a rota administrativa:",
        to.fullPath
      );
    }
  }
});

export default router;
